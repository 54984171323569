import HomeIcon from "@mui/icons-material/Home";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import ContactPageIcon from "@mui/icons-material/ContactPage";

import { routes as staticRoutes } from "../constants";
export const routes = [
	{
		nav: staticRoutes.home,
		drawer: {
			iconJsx: <HomeIcon />,
			text: staticRoutes.home.name,
		},
		jsx: () => <div>Home!</div>,
	},
	{
		nav: staticRoutes.menu1,
		drawer: {
			iconJsx: <RestaurantIcon />,
			text: staticRoutes.menu1.name,
		},
		jsx: () => <div>Menu 1!</div>,
	},
	{
		nav: staticRoutes.menu2,
		drawer: {
			iconJsx: <RestaurantIcon />,
			text: staticRoutes.menu2.name,
		},
		jsx: () => <div>Menu 2!</div>,
	},
	{
		nav: staticRoutes.contactUs,
		drawer: {
			iconJsx: <ContactPageIcon />,
			text: staticRoutes.contactUs.name,
		},
		jsx: () => <div>Contact Page Details!</div>,
	},
];
