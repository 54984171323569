import { viewportSize } from "../viewportSize";
import React from "react";

export interface SizedProps<TLarge extends JSX.Element, TSmall extends JSX.Element, TId extends string = string> {
	large: TLarge;
	small: TSmall;
	containerId?: TId;
}
export const Sized = <TLarge extends JSX.Element, TSmall extends JSX.Element>({
	large,
	small,
	containerId,
}: SizedProps<TLarge, TSmall>) => <div data-testid={containerId}>{viewportSize().isLarge ? large : small}</div>;
