import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

import { Layout } from "./presentation/Layout";
import { theme } from "./presentation/theme";

import "./App.css";

function App() {
	return (
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<Layout />
			</ThemeProvider>
		</BrowserRouter>
	);
}

export default App;
