const ids = {
	CONTAINER: "layout-container",
	SMALL_LAYOUT: "layout-small",
	LARGE_LAYOUT: "layout-large",

	SMALL_NAV: "small-nav",
	SMALL_MAIN: "small-main",
} as const;

export default ids;
