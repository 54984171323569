export const routes = {
	home: {
		name: "Home",
		to: "/",
	},
	menu1: {
		name: "Menu 1",
		to: "/menu-1",
	},
	menu2: {
		name: "Menu 2",
		to: "/menu-2",
	},
	contactUs: {
		name: "Contact Us",
		to: "/contact",
	},
} as const;
