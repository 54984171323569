import React, { useState } from "react";
import { Route, Link, Routes } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

import { routes } from "../../routes";
import id from "../id";

type AnchorDirection = "top" | "left" | "bottom" | "right";
type DrawerUIEvent = React.KeyboardEvent | React.MouseEvent;

const anchorDirection: AnchorDirection = "right";
const toggleDrawerBy =
	(setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>) => (open: boolean) => (event: DrawerUIEvent) => {
		if (isKeyboardEvent(event) && ["Tab", "Shift"].includes(event.key)) return;
		setDrawerOpen(open);
	};

const subList = (routeSlice: typeof routes) => (
	<List>
		{routeSlice
			.map((route) => ({ to: route.nav.to, ...route.drawer }))
			.map(({ text, iconJsx, to }) => (
				<Link to={to}>
					<ListItem button key={text}>
						<ListItemIcon>{iconJsx}</ListItemIcon>
						<ListItemText primary={text} />
					</ListItem>
				</Link>
			))}
	</List>
);

const subLists = (...tuples: ([number, number] | [number])[]) =>
	tuples
		.map(([start, endExclusive]) => subList(routes.slice(start, endExclusive)))
		.reduce((left, right) => (
			<React.Fragment>
				{left}
				<Divider />
				{right}
			</React.Fragment>
		));
const list = (anchor: AnchorDirection, toggleDrawer: (open: boolean) => (event: DrawerUIEvent) => void) => (
	<Box
		sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
		role="presentation"
		onClick={toggleDrawer(false)}
		onKeyDown={toggleDrawer(false)}
	>
		{subLists([0, 1], [1, 3], [3])}
	</Box>
);
const isKeyboardEvent = (event: React.KeyboardEvent | React.MouseEvent): event is React.KeyboardEvent =>
	event.type === "keydown";
export const Small = () => {
	const [drawerOpen, setDrawerOpen] = useState(false);

	const toggleDrawer = toggleDrawerBy(setDrawerOpen);
	return (
		<div data-testid={id.SMALL_LAYOUT}>
			<Drawer anchor={anchorDirection} open={drawerOpen} onClose={toggleDrawer(false)}>
				{list(anchorDirection, toggleDrawer)}
			</Drawer>
			<Box sx={{ flexGrow: 1 }}>
				<AppBar position="static">
					<Toolbar>
						<Typography component="span" sx={{ flexGrow: 1 }}></Typography>
						<IconButton
							onClick={toggleDrawer(true)}
							size="large"
							edge="end"
							color="inherit"
							aria-label="menu"
							sx={{ ml: 2 }}
						>
							<MenuIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
			</Box>
			<main data-testid={id.SMALL_MAIN}>
				<Routes>
					{routes
						.map((route) => [route.nav.to, <route.jsx />] as const)
						.map(([to, JSX]) => (
							<Route key={to} path={to} element={JSX}></Route>
						))}
				</Routes>
			</main>
		</div>
	);
};
